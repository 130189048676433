var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-main"},[_c('searchHeader',{attrs:{"model":_vm.inputs},on:{"search":_vm.searchBtn}}),_c('vxe-table',{staticClass:"mytable-scrollbar forms",attrs:{"stripe":"","highlight-hover-row":"","size":"small","data":_vm.tableData}},[_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"65","fixed":"left"}}),_c('vxe-column',{attrs:{"title":"序号","min-width":"120","fixed":"left"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("组织名称")]),_c('p',[_vm._v("统一社会信用代码")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.invite_name||'--'))]),_c('div',[_vm._v(_vm._s(row.invite_code||'--'))])]}}])}),_c('vxe-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("管理员姓名")]),_c('p',[_vm._v("联系方式")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.invite_contact_name||'--'))]),_c('div',[_vm._v(_vm._s(row.invite_contact_mobile||'--'))])]}}])}),_c('vxe-column',{attrs:{"title":"状态","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status==1)?_c('el-tag',{attrs:{"size":"mini","type":"warning"}},[_vm._v("待激活")]):_vm._e(),(row.status==2)?_c('el-tag',{attrs:{"size":"mini"}},[_vm._v("已激活")]):_vm._e(),(row.status==3)?_c('el-tag',{attrs:{"size":"mini","type":"success"}},[_vm._v("已提交")]):_vm._e()]}}])}),_c('vxe-column',{attrs:{"title":"租户类型","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.invite_type==1)?[_c('el-tag',{attrs:{"size":"mini","type":"warning"}},[_vm._v("独立租户")])]:(row.invite_type==2)?[_c('el-tag',{attrs:{"size":"mini","type":"success"}},[_vm._v("分平台")])]:(row.invite_type==3)?[_c('el-tag',{attrs:{"size":"mini","type":"success"}},[_vm._v("用户")])]:[_c('el-tag',{attrs:{"size":"mini"}},[_vm._v("普通租户")])]]}}])}),_c('vxe-column',{attrs:{"title":"邀请方式","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.invite_method==1)?_c('span',[_vm._v("定向邀请")]):_vm._e(),(row.invite_method==2)?_c('span',[_vm._v("公开邀请")]):_vm._e()]}}])}),_c('vxe-column',{attrs:{"title":"邀请链接","min-width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-popover',{key:_vm.code_key,attrs:{"placement":"top","width":"140","trigger":"click"}},[_c('div',{staticClass:"invited-code-img-box"},[_c('img',{attrs:{"src":row.code_url}})]),_c('span',{staticClass:"hover-color",attrs:{"slot":"reference"},on:{"click":function($event){return _vm.popoverBtn(row)}},slot:"reference"},[_vm._v(_vm._s(row.invite_link))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("邀请人")]),_c('p',[_vm._v("邀请时间")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.created_at||'--')+" ")]),_c('div',[_vm._v(" "+_vm._s(row.created_by||'--')+" ")])]}}])}),_c('vxe-column',{attrs:{"title":"操作","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [_c('div',{staticClass:"action-box"},[_c('el-link',{staticClass:"links",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.recordBtn(row)}}},[_vm._v(" 记录 ")]),_c('el-link',{staticClass:"links",class:['copy-text'+rowIndex],attrs:{"data-clipboard-text":row.invite_link,"underline":false,"type":"primary"},on:{"click":function($event){return _vm.copyBtn(row,rowIndex)}}},[_vm._v(" 复制链接 ")])],1)]}}])})],1),_c('pageNumber',{attrs:{"pages":_vm.pages},on:{"changePage":_vm.changePage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }