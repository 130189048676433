<template>
<div class="container-main">
	<!-- <navHeader :headers="headers"></navHeader> -->
	<searchHeader :model="inputs" @search="searchBtn" />
	<!-- <div class="add-btn-box">
		<el-button size="small" type="primary" class="add-btn" @click="addItemBtn">新增邀请</el-button>
	</div> -->
	<vxe-table
		class="mytable-scrollbar forms"
		stripe
		highlight-hover-row
		size="small"
		:data="tableData" >
		<vxe-column type="seq" title="序号" width="65" fixed="left"></vxe-column>
		<vxe-column title="序号" min-width="120" fixed="left">
			<template #header>
				<p>组织名称</p>
				<p>统一社会信用代码</p>
			</template>
			<template #default="{row}">
				<div>{{row.invite_name||'--'}}</div>
				<div>{{row.invite_code||'--'}}</div>
			</template>
		</vxe-column>
		<vxe-column min-width="140">
			<template #header>
				<p>管理员姓名</p>
				<p>联系方式</p>
			</template>
			<template #default="{row}">
				<div>{{row.invite_contact_name||'--'}}</div>
				<div>{{row.invite_contact_mobile||'--'}}</div>
			</template>
		</vxe-column>
		<vxe-column title="状态" width="90">
			<template #default="{row}">
				<el-tag size="mini" type="warning" v-if="row.status==1">待激活</el-tag>
				<el-tag size="mini" v-if="row.status==2">已激活</el-tag>
				<el-tag size="mini" type="success" v-if="row.status==3">已提交</el-tag>
			</template>
		</vxe-column>
		<vxe-column title="租户类型" width="90">
			<template #default="{row}">
				<template v-if="row.invite_type==1">
					<el-tag size="mini" type="warning">独立租户</el-tag>
				</template>
				<template v-else-if="row.invite_type==2">
					<el-tag size="mini" type="success">分平台</el-tag>
				</template>
				<template v-else-if="row.invite_type==3">
					<el-tag size="mini" type="success">用户</el-tag>
				</template>
				<template v-else>
					<el-tag size="mini" >普通租户</el-tag>
				</template>
			</template>
		</vxe-column>
		<vxe-column title="邀请方式" width="100">
			<template #default="{row}">
				<span v-if="row.invite_method==1">定向邀请</span>
				<span v-if="row.invite_method==2">公开邀请</span>
			</template>
		</vxe-column>
		<vxe-column title="邀请链接" min-width="140">
			<template #default="{row}">
				<el-popover :key="code_key"
				    placement="top"
				    width="140"
				    trigger="click">
					<div class="invited-code-img-box">
						<img :src="row.code_url" >
					</div>
					<span slot="reference" class="hover-color" @click="popoverBtn(row)">{{row.invite_link}}</span>
				</el-popover>
			</template>
		</vxe-column>
		<vxe-column min-width="140">
			<template #header>
				<p>邀请人</p>
				<p>邀请时间</p>
			</template>
			<template #default="{row}">
				<div>
					{{row.created_at||'--'}}
				</div>
				<div>
					{{row.created_by||'--'}}
				</div>
			</template>
		</vxe-column>
		<vxe-column title="操作" width="140">
			<template #default="{row,rowIndex}">
				<div class="action-box">
					<el-link class="links" :underline="false" type="primary" @click="recordBtn(row)">
					  记录
					</el-link>
					<el-link class="links" :class="['copy-text'+rowIndex]" 
						:data-clipboard-text="row.invite_link"  
						:underline="false" type="primary" 
						@click="copyBtn(row,rowIndex)">
					  复制链接
					</el-link>
				</div>
				
			</template>
		</vxe-column>
	</vxe-table>
	<pageNumber  @changePage="changePage" :pages="pages"></pageNumber>
	
</div>
</template>

<script>
	let sourceArr = [
		{name:'全部',value:-1},
		{name:'独立租户',value:1},
		{name:'分平台租户',value:2},
	]
	let statusArr = [
		{name:'定向邀请',value:1},
		{name:'公开邀请',value:2},
	]
	import Clipboard from 'clipboard'
	import pageNumber from "@/components/phmAll/pageNumber.vue";
	import textOver from '@/components/phmAll/textOver'
	import searchHeader from '@/components/phmAll/searchHeader'
	
	export default {
		components: {
			pageNumber,
			textOver,
			searchHeader,
		},
		data(){
			return {
				headers:{
					title:'邀请管理',
				},
				inputs:[
					{type:"input", field: "invite_name",label: "组织名称",value: ""},
					{type:"input", field: "invite_code",label: "统一社会信用代码",value: ""},
					// {type:"select", field: "status",label: "状态",value: "", multiple: 'false',status:false, options:statusArr},
					{type:"select", field: "invite_method",label: "邀请方式",value: "",multiple:'false', options:statusArr},
					{type:"input", field: "created_by",label: "邀请人",value: ""},
					{type:"time", field: "created_at",label: "邀请时间",value:[]},
				],
				tableData:[],
				searchWords:{},
				code_key:+new Date(),
				pages: {
				  pageNum: 1,
				  pageSize: 10,
				  total: 10,
				},
			}
		},
		created(){
			this.getListData();
		},
		mounted(){
			this.$store.commit("updatePageNav", [
			  { name: "邀请租户"},
			  { name: "邀请记录"},
			]);
		},
		methods: {
			searchBtn(data){
				 this.searchWords = data;
				 this.getListData();
			},
			popoverBtn(row){
				if(row.code_url){
					return 1
				}
				let data = {
					content:row.invite_link,
					img_size:100,
				}
				this.$utils.getQrcode(data).then(res=>{
					row.code_url = res.img;
				}).catch(err=>{
					console.log('获取失败',err);
				})
			},
			addItemBtn(){
				this.$router.push({
					path:'/tenantManage/invitedCenter/invitedEdit'
				})
			},
			detailBtn(row){
				this.$router.push({
					path:'/operationOwns/tenantCenter/detail',
					query:{
						id:row.id,
					}
				})
			},
			copyBtn(row,index){
				var clipboard = new Clipboard('.copy-text'+index);
				let _this = this;
				clipboard.on('success', e => {
					_this.$message.success('复制成功');
				})
				clipboard.on('error', e =>{
					_this.$Message.error('该浏览器不支持复制');
				})
				this.clipboard = clipboard;
				setTimeout(()=>{
					this.clipboard.destroy &&this.clipboard.destroy()
				},500)
			},
			recordBtn(row){
				this.$router.push({
					path:'/mySelf/invitedCompany/invitedDetail',
					query:{
						code:row.serial_number,
						type:row.invite_method,
					}
				})
			},
			changePage(val){
				if (val.type == "size") {
				  this.pages.pageSize = val.num;
				} else {
				  this.pages.pageNum = val.num;
				}
				this.getListData();
			},
			getListData(){
				let data = {
					page:this.pages.pageNum,
					page_size:this.pages.pageSize,
					invite_place:1,
				}
				for(let key in this.searchWords){
						data[key] = this.searchWords[key];
				}
				this.$axios.postJSON("/base/invite/common/list",data).then(res=>{
					if(res.code==200){
						this.pages.total = res.data.total_count;
						let list = res.data?.list;
						if(list && list.length>0){
							list.map(item=>{
								item.show_code = false;
								item.code_url = "";
								if(item.station_info && item.station_info.admin_info ){
									item.admin_email = item.station_info.admin_info.email
								}
							})
							this.tableData = list;
						}
					}
				}).catch(err=>{
					console.log(err);
					err.msg && this.$message.error(err.msg)
				})
			},
		},
	}
</script>

<style scoped lang="scss">
$primary:#F26161;
.container-main{
	width:1200px;
	margin:0 auto;
	padding:20px;
	box-sizing: border-box;
	background-color: #FFFFFF;
}
.invited-code-img-box{
	display: flex;
	justify-content: center;
	align-items: center;
	width:140px;
	height: 140px;
}
.hover-color{
	&:hover{
		color:$primary;
		cursor: pointer;
	}
}	
.add-btn-box{
	margin-top: 12px;
	margin-bottom:10px;
	text-align: right;
	.add-btn{
		width:80px;
	}
}
.copy-box{
	display: flex;
}
.action-box{
	.links{
		margin: 0 5px;
	}
}
.type-name{
	width:90px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border:1px solid $primary;
	color:$primary;
	border-radius: 2px;
	&.icon2{
		border-color:#F8B343;
		color:#F8B343;
	}
}
</style>
